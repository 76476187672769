
const scopes = [
    {
        name: 'Accounts',
        description: 'Accounts related scopes',
        scopes: [
            {
                key: 'read_accounts',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre los clientes.'
            }, 
            {
                key: 'write_accounts',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones a los datos de los clientes'
            }
        ]
    },
    {
        name: 'Activations',
        description: 'Activations related scopes',
        scopes: [
            {
                key: 'read_activations',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre las activaciones del procesador de Ecart Pay.'
            },
            {
                key: 'write_activations',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones a la información y estado de las activaciones del procesador de Ecart Pay.'
            }
        ]
    },
    {
        name: 'Activity',
        description: 'Activity related scopes',
        scopes: [
            {
                key: 'read_activity',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre la actividad.'
            }
        ]
    },
    {
        name: 'Admins',
        description: 'Admins related scopes',
        scopes: [
            {
                key: 'read_admins',
                name: 'Read',
                description: 'Este scope permite leer información de los administradores.'
            },
            {
                key: 'write_admins',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar operaciones de escritura y modificación de los datos de los administradores.'
            }
        ]
    },
    {
        name: 'Billings',
        description: 'Billings related scopes',
        scopes: [
            {
                key: 'read_billings',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre las facturaciones.'
            },
            {
                key: 'write_billings',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones a los datos de las facturaciones.'
            }
        ]
    },
    {
        name: 'Chargebacks',
        description: 'Chargebacks related scopes',
        scopes: [
            {
                key: 'read_chargebacks',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre los contracargos de los clientes.'
            },
            {
                key: 'write_chargebacks',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones de los datos de los contracargos.'
            }
        ]
    },
    {
        name: 'Gateways',
        description: 'Gateways related scopes',
        scopes: [
            {
                key: 'read_gateways',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre los gateways.'
            },
            {
                key: 'write_gateways',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones a los datos de los gateways.'
            }
        ]
    },
    {
        name: 'Invoices',
        description: 'Invoices related scopes',
        scopes: [
            {
                key: 'read_invoices',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre los invoices de los clientes.'
            },
            {
                key: 'write_invoices',
                name: 'Write',
                description: 'Este scope permite a los usuarios timbrar los invoices de los clientes.'
            }
        ]
    },
    {
        name: 'Leads',
        description: 'Leads related scopes',
        scopes: [
            {
                key: 'read_leads',
                name: 'Read',
                description: 'Este scope permite leer información de los leads.'
            },
            {
                key: 'write_leads',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar operaciones de escritura y modificación de los datos de los leads.'
            }
        ]
    },
    {
        name: 'Limits',
        description: 'Limits related scopes',
        scopes: [
            {
                key: 'read_limits',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre los límites.'
            },
            {
                key: 'write_limits',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones a los límites.'
            }
        ]
    },
    {
        name: 'Payouts',
        description: 'Payouts related scopes',
        scopes: [
            {
                key: 'read_payouts',
                name: 'Read',
                description: 'Este scope permite leer información de los pagos a los clientes.'
            }
        ]
    },
    {
        name: 'Pricings',
        description: 'Pricings related scopes',
        scopes: [
            {
                key: 'read_pricings',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre los precios.'
            },
            {
                key: 'write_pricings',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones a los precios.'
            }
        ]
    },
    {
        name: 'Profiles',
        description: 'Profiles related scopes',
        scopes: [
            {
                key: 'read_profiles',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre los perfiles.'
            },
            {
                key: 'write_profiles',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones a los datos de los perfiles.'
            }
        ]
    },
    {
        name: 'Rating',
        description: 'Rating related account',
        scopes: [
            {
                key: 'write_ratings',
                name: 'Write',
                description: 'Este scope permite a los administradores modificar el rating de las cuentas.'
            }
        ]
    },
    {
        name: 'Refunds',
        description: 'Refunds related scopes',
        scopes: [
            {
                key: 'read_refunds',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre los rembolsos'
            },
            {
                key: 'write_refunds',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones a los datos de los rembolsos.'
            }
        ]
    },
    {
        name: 'Reports',
        description: 'Reports related scopes',
        scopes: [
            {
                key: 'read_reports',
                name: 'Read',
                description: 'Este scope permite a los usuarios generar y descargar reportes.'
            }
        ]
    },
    {
        name: 'Risks',
        description: 'Risks related scopes',
        scopes: [
            {
                key: 'read_risks',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre los riesgos.'
            },
            {
                key: 'write_risks',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones a los datos de los riesgos.'
            }
        ]
    },
    {
        name: 'Rules',
        description: 'Risks related scopes',
        scopes: [
            {
                key: 'read_rules',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre las reglas de riesgo.'
            }
        ]
    },
    {
        name: 'Transactions',
        description: 'Transactions related scopes',
        scopes: [
            {
                key: 'read_transactions',
                name: 'Read',
                description: 'Este scope permite leer información de las transacciones de los clientes.'
            },
            {
                key: 'write_transactions',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones a los datos de las transacciones.'
            }
        ]
    },
    {
        name: 'Transfers',
        description: 'Transfers related scopes',
        scopes: [
            {
                key: 'read_transfers',
                name: 'Read',
                description: 'Este scope permite leer información de las transferencias de los clientes.'
            },
        ]
    },
    {
        name: 'Utils',
        description: 'Utils related scopes',
        scopes: [
            {
                key: 'read_utils',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre las utilidades.'
            }
        ]
    },
    {
        name: 'Webhook Logs',
        description: 'Webhook logs related scopes',
        scopes: [
            {
                key: 'read_webhookslogs',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer los registros de eventos de webhooks.'
            }
        ]
    },
    {
        name: 'Whitelist',
        description: 'Whitelist related scopes',
        scopes: [
            {
                key: 'read_whitelist',
                name: 'Read',
                description: 'Este scope permite a los usuarios leer información sobre la lista blanca.'
            },
            {
                key: 'write_whitelist',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones a la lista blanca.'
            }
        ]
    },
    {
        name: 'Withdrawals',
        description: 'Withdrawals related scopes',
        scopes: [
            {
                key: 'read_withdrawals',
                name: 'Read',
                description: 'Este scope permite leer información de los retiros de los clientes.'
            }, 
            {
                key: 'write_withdrawals',
                name: 'Write',
                description: 'Este scope permite a los usuarios realizar modificaciones a los datos de los retiros.'
            },
        ]
    },
];

export default scopes;