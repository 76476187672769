import { createApp } from 'vue';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { api } from '@/services';
import cookies from '@/mixins/cookies.mixin';
import { sanitizeUrl } from '@braintree/sanitize-url';
import vClickOutside from 'v-click-outside';

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

import '@/assets/scss/styles.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'vue3-perfect-scrollbar/dist/style.css';
import '../public/css/main.css';
import store from '@/store';

import App from './App.vue';
import router from './routes';
import '@/config';

// Plugins
import toast from '@/plugins/toast';
import vueTelInput from '@/plugins/vue-tel-input';

// mixins 
import scopesMixin from '@/mixins/scopes.mixin';
import filters from '@/mixins/filters.mixin';
import globalMixin from '@/mixins/global.mixins.js';

// custom bootstrap components 
import BComponents from '@/components/bootstrap';

// Vue scrollbar
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';

const app = createApp(App);
app.use(BComponents);
app.use(PerfectScrollbarPlugin);
app.use(router);
app.use(toast);
app.use(store)
app.use(vueTelInput);
app.mixin(cookies);
app.mixin(filters);
app.mixin(globalMixin);
app.mixin(scopesMixin);

store.$axios = api.client;

app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$axios = api.client;
app.config.globalProperties.$sanitize = sanitizeUrl;

const { bind, unbind } = vClickOutside.directive;
app.directive('click-outside', {
  mounted(el, bindling) {
    bind(el, { value: bindling.value });
  },
  beforeUnmount(el) {
    unbind(el);
  },
});

app.directive('sanitize-href', {
  mounted(el, binding) {
    if (binding.value) {
      el.href = sanitizeUrl(binding.value);
    }
  },
  updated(el, binding) {
    if (binding.value) {
      el.href = sanitizeUrl(binding.value);
    }
  }
})

app.mount('#app');
