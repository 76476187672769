<template>
        <div class="sidebar print">
            <div class="sidebar__content" :class="{ 'sidebar__content--open': open }">
                <perfect-scrollbar :class="{ 'sidebar__scrollbar': !open }" :options="scrollbarOptions">
                    <div class="sidebar__nav-container">
                        <ul class="sidebar__nav">
                            <li v-for="items in links" :key="items" class="sidebar__nav-item"
                            @mouseenter="showTooltip(items.name)" @mouseleave="hideTooltip(items.name)">
                                <router-link v-if="items.path" class="sidebar__nav-link" :to="items.path" active-class="sidebar__nav-link--active">
                                <div @click="toggleGroup(items.name)">
                                    <span class="sidebar__nav-icon" :class="items.icon"></span>
                                    <span class="sidebar__nav-tittle" :class="{ 'sidebar__nav-link--close': !open }">{{ items.name }}</span>
                                    <span v-if="isGroupOpen(items.name)" :class="{ 'sidebar__nav-link--close': !open }" class="sidebar__nav-arrow fas fa-angle-down"></span>
                                    <span v-else :class="{ 'sidebar__nav-link--close': !open }" class="sidebar__nav-arrow fas fa-angle-right"></span>
                                </div> 
                                </router-link>
                                <div v-else class="sidebar__nav-link" @click="toggleGroup(items.name)">
                                    <span class="sidebar__nav-icon" :class="items.icon"></span>
                                    <span class="sidebar__nav-tittle" :class="{ 'sidebar__nav-link--close': !open }">{{ items.name }}</span>
                                    <span v-if="isGroupOpen(items.name)" :class="{ 'sidebar__nav-link--close': !open }" class="sidebar__nav-arrow fas fa-angle-down"></span>
                                    <span v-else :class="{ 'sidebar__nav-link--close': !open }" class="sidebar__nav-arrow fas fa-angle-right"></span>
                                </div> 
                            <div class="sidebar__nav-list" v-if="items.children && open && isGroupOpen(items.name)">
                                <template v-for="item in items.children" :key="item.name">
                                    <li class="sidebar__nav-item" v-if="CAN(item.scope)">
                                        <router-link :to="item.path" class="sidebar__nav-sub-link" active-class="sidebar__nav-sub-link--active">
                                            <span :class="{ 'sidebar__nav-link--close': !open }">{{ item.name }}</span>
                                        </router-link>
                                    </li>
                                </template>
                            </div>
                            <div v-if="items.children && !open && tooltip[items.name]" class="sidebar__tooltip">
                                <div class="sidebar__nav-subtitle">
                                    {{ items.name }}
                                </div>
                                <li v-for="item in items.children" :key="item.name" class="sidebar__nav-item">
                                    <router-link :to="item.path" class="sidebar__nav-sub-link" active-class="sidebar__nav-sub-link--active">{{ item.name }}</router-link>
                                </li>
                            </div>
                            </li>
                        </ul>
                        <ul class="sidebar__nav sidebar__nav--other-links">
                            <li v-for="item in sidebar.otherLinks" :key="item.name" class="sidebar__nav-item">
                                <router-link :to="item.path" class="sidebar__nav-link" active-class="sidebar__nav-link--active">
                                    <span class="sidebar__nav-icon" :class="item.icon"></span>
                                    <span class="sidebar__nav-text" :class="{ 'sidebar__nav-link--close': !open }">{{ item.name }}</span>
                                </router-link>
                                <button @click="$emit('logout')" class="sidebar__nav-link" style="appearance: none;">
                                    <span class="sidebar__nav-icon fas fa-sign-out-alt"></span><span :class="{ 'sidebar__nav-link--close': !open }">Logout</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </perfect-scrollbar>
                <button class="sidebar__button-collapse" :class="{ 'sidebar__button-collapse--open': open }" @click="$emit('toggleSidebar')">
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.98614 7.20303L16.3611 0.828027C16.8018 0.387402 17.5143 0.387402 17.9502 0.828027L19.0096 1.8874C19.4502 2.32803 19.4502 3.04053 19.0096 3.47646L14.4955 7.9999L19.0143 12.5187C19.4549 12.9593 19.4549 13.6718 19.0143 14.1077L17.9549 15.1718C17.5143 15.6124 16.8018 15.6124 16.3658 15.1718L9.99082 8.79678C9.54551 8.35615 9.54551 7.64365 9.98614 7.20303ZM0.986135 8.79678L7.36114 15.1718C7.80176 15.6124 8.51426 15.6124 8.9502 15.1718L10.0096 14.1124C10.4502 13.6718 10.4502 12.9593 10.0096 12.5233L5.49551 7.9999L10.0143 3.48115C10.4549 3.04053 10.4549 2.32803 10.0143 1.89209L8.95489 0.828027C8.51426 0.387402 7.80176 0.387402 7.36582 0.828027L0.990823 7.20303C0.54551 7.64365 0.54551 8.35615 0.986135 8.79678Z"
                            fill="#555555" />
                    </svg>
                </button>
            </div>
            <div class="sidebar__backdrop" :class="{ 'sidebar__backdrop--open': open }" @click="$emit('toggleSidebar')"></div>
        </div>
</template>

<script>
import { sidebar, scopes } from '@/data';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar' ;

import { mapState } from 'vuex';
export default {
    components: { PerfectScrollbar },
    name: 'Sidebar',
    props: {
        open: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            sidebar,
            scopes,
            scrollbarOptions: {
                suppressScrollX: true,
                wheelPropagation: false,
            },
            filter: false,
            links: [],
            openGroups: {
                'Accounts': true,
                'Process': true,
                'Administration': true,
            },
            tooltip: {},
            activeLinks: new Set()
        };
    },
    computed: {
        ...mapState(['globalFilters']),
    },
    mounted() {
        this.checkFilters();
        this.checkActiveLinks();
    },
    watch: {
        open: {
            handler(newVal) {
                if (newVal === false) {
                    this.openGroups = {
                        'Accounts': false,
                        'Movements': false,
                        'Administration': false,
                    };

                    this.scrollbarOptions = {
                        suppressScrollX: true,
                        suppressScrollY: true, 
                        wheelPropagation: false,
                    };
                }else{
                    this.scrollbarOptions = {
                        suppressScrollX: true,
                        wheelPropagation: true,
                    };
                }
            },
        },
        globalFilters: {
            handler(newVal) {
                if (newVal.account != 0) {
                    this.filter = true;
                    this.sidebar.links[0].path = `/accounts/info?id=${newVal.account.id}`;
                    this.sidebar.links[0].children[0].path = `/accounts/info?id=${newVal.account.id}`;
                    this.checkFilters();
                } else {
                    this.filter = false;
                    this.sidebar.links[0].path = `/accounts`;
                    this.sidebar.links[0].children[0].path = `/accounts`;
                    this.checkFilters();
                }
            },
            deep: true,
        },
        $route() {
            this.checkActiveLinks();
        },
    },
    methods: {
        showTooltip(group) {
            if (!this.open) {
                this.tooltip = { ...this.tooltip, [group]: true };
            }
        },
        hideTooltip(group) {
            this.tooltip = { ...this.tooltip, [group]: false };
        },
        toggleGroup(group) {
            if (this.open) {
                this.openGroups[group] = !this.openGroups[group];
            }
        },
        isGroupOpen(group) {
            return !!this.openGroups[group];
        },
        checkFilters() {
            if (this.globalFilters.account != 0) {
            this.links = this.sidebar.links
            .map(link => {
                const filteredChildren = link.children.filter(child => child.filtered);
                if (filteredChildren.length > 0) {
                return {
                    ...link,
                    children: filteredChildren
                };
                }
                return null;
            })
            .filter(link => link !== null);
            }else{
                this.links = this.sidebar.links;
            }
        },
        checkActiveLinks() {
            this.$nextTick(() => {
                this.activeLinks.forEach(link => {
                    link.classList.remove('sidebar__nav-link--active');
                });
                this.activeLinks.clear();
                document.querySelectorAll('.sidebar__nav-item').forEach(item => {
                    const activeLink = item.querySelector('.sidebar__nav-link--active') || item.querySelector('.sidebar__nav-sub-link--active');
                    if (activeLink) {
                        const link = item.querySelector('.sidebar__nav-link');
                        if (link && link.classList.contains('sidebar__nav-link')) {
                            link.classList.add('sidebar__nav-link--active');
                            this.activeLinks.add(link);
                        }
                    }
                });
            });
        }
    },
};
</script>

<style lang="scss">
.sidebar {
    position: relative;
    &__content {
        background-color: $white;
        box-shadow: 0 0 12px rgba(#000000, 0.06);
        height: calc(100vh - 60px);
        left: 0;
        position: fixed;
        top: 60px;
        transition: 500ms all;
        width: 100%;
        max-width: 70px;
        z-index: 1029;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .custom-scrollbar {
            overflow: hidden;
            transition: overflow 0.3s ease;
            height: 100%;
            scrollbar-width: thin;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #CACFD9;
                opacity: .45;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &:hover {
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
        
        @media (max-width: 992px) {
            height: 100vh;
            left: -300px;
            top: 0;
            z-index: 1031;
        }
    
        &--open {
            max-width: 250px;
            align-items: normal;

            @media (max-width: 992px) {
                left: 0px;
            }
        }
    }

    &__scrollbar {
        overflow-y: visible !important;
        overflow-x: visible !important;
    }

    &__brand {
        align-items: center;
        display: flex;
        height: 90px;
        justify-content: center;
        width: 90%;
    }

    &__brand-logo {
        width: 150px;
    }

    &__nav-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    &__nav {
        padding: 16px 10px;
        list-style: none;

        &--other-links {
            border-top: 1px solid $ecart-secondary-100;
        }
    }

    &__nav-item {
        width: 100%;
        position: relative;
    }

    &__nav-link {
        background: transparent;
        border: none;
        color: $general-black;
        opacity: 1;
        display: block;
        transition: 200ms all;
        transition-delay: 150ms;
        transition-property: opacity;
        font-weight: 500;
        padding: 6px 6px;
        text-align: left;
        text-decoration: none;
        width: 100%;
        size: 12px;
        margin-top: 5px;
        border-radius: 5px;
        cursor: pointer;

        &--close {
            display: none;
            opacity: 0;
        }
        
        &--active {
            background-color: $ecart-primary-100;

            span {
                color: $ecart-primary;
            }
        }

        &:hover {
            background-color: $ecart-primary-100;
            color: $general-black;
        }
    }

    &__nav-list {
        width: 100%;
        padding-left: 20px;
        list-style: none;
    }
    
    &__nav-sub-link {
        background: transparent;
        border: none;
        border-left: 3px solid transparent;
        color: $general-black;
        opacity: 1;
        display: block;
        transition: 200ms all;
        transition-delay: 150ms;
        transition-property: opacity;
        font-weight: 400;
        text-decoration: none;
        width: 100%;
        padding: 4px 10px;
        margin-top: 10px;
        border-radius: 5px;
        cursor: pointer;

        &--close {
            display: none;
            opacity: 0;
        }
        
        &--active {
            background-color: $ecart-primary-100;
            span {
                color: $ecart-primary;
            }
        }

        &:hover {
            background-color: $ecart-primary-100;
            color: $general-black;
        }
    }

    &__nav-icon {
        margin-right: 10px;
        margin-left: 10px;
        width: 16px;
        height: 16px;
        z-index: 1;
    }

    &__nav-subtitle {
        font-size: 16px;
        font-weight: 500;
        padding: 10px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-bottom: 1px solid $ecart-secondary-200;
    }

    &__nav-tittle {
        font-size: 14px;
        line-height: 14px;
        padding-left: 10px;
    }

    &__nav-arrow {
        width: 12px;
        height: 12px;
        padding-top: 5px;
        float: right;
        
    }

    &__tooltip {
        left: 100%;
        top: 0;
        background: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        padding: 10px;
        border-radius: 4px;
        z-index: 10000;
        position: absolute;
        width: 200px;
        white-space: nowrap;
    }

    &__tooltip-link {
        display: block;
        padding: 5px 10px;
        color: #333;
        text-decoration: none;
    }

    &__tooltip-link:hover {
        background-color: #f0f0f0;
    }

    @media (max-width: 992px) {
        &__backdrop {
            background-color:rgba(33, 40, 55, 0.5);
            display: none;
            height: 100vh;
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: 1030;

            &--open {
                display: block;
            }
        }
    }

    &__button-collapse {
        border: none;
        width: 44px;
        height: 44px;
        background: $general-white;
        right: -22px;
        position: absolute;
        bottom: 100px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: 500ms all;
        transform: rotate(180deg);

        &--open {
            transform: rotate(0deg);
        }
    }
}
</style>
