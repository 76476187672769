<template>
    <div v-if="!avatarHasImage" :style="{ backgroundColor: getBackgroundColor }" :class="[size]" class="avatar">
        {{ initials }}
    </div>
    <div v-else :class="[size]" class="avatar">
        <img
            ref="image"
            :src="propImageUpdated || updatedAccountImage"
            alt="Profile picture"
            class="edit-picture-box position-relative profile-picture NO-CACHE"
        />
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: '',
        },

        lastName: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'md',
        },

        image: {
            type: String,
            default: null,
        },

        account: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            colors: [
                '#EF5350',
                '#9C27B0',
                '#7E57C2',
                '#5C6BC0',
                '#BF360C',
                '#42A5F5',
                '#26A69A',
                '#283593',
                '#66BB6A',
                '#78909C',
                '#FF7043',
                '#8D6E63',
                '#1976D2',
                '#8E24AA',
                '#C2185B',
                '#3D5AFE',
                '#0097A7',
                '#FF6F00',
                '#4527A0',
            ],
        };
    },

    computed: {
        initials() {
            try {
                const name = (this.account && this.account.first_name) || this.name;
                const lastName = (this.account && this.account.last_name) || this.lastName;
                const nameInitial = typeof name === 'string' && name.length ? name.split(' ')[0][0] : '';
                const lastNameInitial = typeof lastName === 'string' && lastName.length ? lastName.split(' ')[0][0] : '';
                return nameInitial.toUpperCase() + lastNameInitial.toUpperCase();
            } catch (error) {
                return 'EP';
            }
        },

        getBackgroundColor() {
            return this.colors[this.numberFromText(this.initials) % this.colors.length];
        },

        avatarHasImage() {
            return this.image || this.account.image;
        },

        updatedAccountImage() {
            const { image } = this.account;
            const timestamp = new Date().getTime();
            return `${image}?t="${timestamp}"`;
        },

        propImageUpdated() {
            const timestamp = new Date().getTime();
            if (this.image && this.image.includes('https')) {
                return `${this.image}?t="${timestamp}"`;
            }
            return this.image;
        },
    },

    methods: {
        numberFromText(initials) {
            const charCodes = initials
                .split('')
                .map(char => char.charCodeAt(0))
                .join('');
            return parseInt(charCodes, 10);
        },
    },
};
</script>

<style lang="scss" scoped>
.avatar {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: $gray-600;
    border-radius: 50%;
    color: white;
    font-weight: 400;
    font-size: 1.8rem;
    &.xs {
        width: 30px;
        height: 30px;
        font-size: 1.1rem;
        padding: 0;
    }
    &.sm {
        width: 50px;
        height: 50px;
        font-size: 1.3rem;
    }
    &.lg {
        width: 70px;
        height: 70px;
        font-size: 2rem;
    }
    &.xl {
        width: 80px;
        height: 80px;
        font-size: 2.3rem;
    }
    &.xxl {
        width: 100px;
        height: 100px;
        font-size: 2.3rem;
    }
}

.profile-picture-container {
    width: 60px;
    height: 60px;
    &.xs {
        width: 30px;
        height: 30px;
        font-size: 1.1rem;
        padding: 0;
    }
    &.sm {
        width: 50px;
        height: 50px;
        font-size: 1.3rem;
        padding: 0;
    }
    &.lg {
        width: 70px;
        height: 70px;
        font-size: 2rem;
        padding: 0;
    }
}

.profile-picture {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
