<template>
    <div class="header print" :class="{ 'header--open': open }">
        <div class="header__left-container">
            <img src="@/assets/logo-negro.png" class="header__brand-logo img-fluid" alt="Tendencys" />
            <img src="@/assets/logo-negro-movil.png" class="header__brand img-fluid" alt="Tendencys" />
        </div>
        <div class="header__right-container">
            <div>
                <SearchInput class="header__account-selector"/>
            </div>
            <div>
                <ui-select
                    id="type"
                    class="header__country-selector"
                    name="type"
                    label="Country"
                    option-label="name"
                    option-value="code"
                    chose-option-text="All countries"
                    v-model:value="countryFilter"
                    :options="countries"
                />
            </div>
            <button class="header__notification-button">
                <span class="header__options fas fa-question-circle"></span>
            </button>
            <button class="header__notification-button">
                <span class="header__options fas fa-bell"></span>
            </button>
            <div v-if="account" class="header__menu">
                <button class="header__profile-dropdown" @click="toggleProfileMenu">
                    <div class="header__avatar">
                        <ui-avatar ref="avatar" size="xs" :account="account" />
                    </div> 
                    <div>
                        <div class="header__profile-name">{{ fullName }}</div>
                        <div class="header__profile-email">{{ account.email }}</div>
                    </div> 
                </button>
                <div v-if="isProfileMenuVisible" class="profile-menu-overlay" @click="closeProfileMenu">
                    <div class="profile-menu" @click.prevent>
                        <ul>
                            <li @click="$router.push('/dashboard/profile')">Your profile</li>
                            <li @click="$emit('logout')">Logout</li>
                        </ul>
                    </div>
                </div>
            </div>
            <button class="header__sidebar-button" @click="$emit('sidebarButton')">
                <span class="header__options fas fa-bars"></span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import UiAvatar from '@/components/ui/Avatar.vue';
import UiSelect from '@/components/ui/Select';
import SearchInput from '@/components/layouts/dashboard/SearchInput.vue';
import { countries } from '@/data';

export default {
    name: 'Header',
    components: { UiAvatar, UiSelect, SearchInput },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            countries: countries.map(country => ({
                ...country,
                image: `https://envia-clients-new.herokuapp.com/flags/${country.code.toLowerCase()}.svg`
            })),
            isProfileMenuVisible: false,
        };
    },
    computed: {
        ...mapGetters({
            fullName: 'accounts/fullName',
            country: 'globalFilters/country',
        }),
        ...mapState('accounts', ['account']),
        countryFilter: {
            get: function () {
                return this.country;
            },
            set: function (newValue) {
                this.setCountry(newValue || '');
            },
        },
    },
    methods: {
        ...mapActions({ setCountry: 'globalFilters/setCountry' }),
        toggleProfileMenu() {
            this.isProfileMenuVisible = !this.isProfileMenuVisible;
        },
        closeProfileMenu() {
            this.isProfileMenuVisible = false;
        },
    }
};
</script>

<style lang="scss">
.header {
    align-items: center;
    border-bottom: 1px solid $ecart-secondary-200;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    display: flex;
    background: $white;
    height: 60px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1030;
    transition: 300ms all;
    width: 100vw;

    @media(max-width: 992px) {
        width: 100vw;
    }

    @media(max-width: 768px) {
    }

    &--open {
        width: 100vw;
    
        @media (max-width: 768px) {
            width: 100vw;
            padding-left: 0;
            padding-right: 1rem;
        }
    }

    &__right-container {
        align-items: center;
        display: flex;
        margin-left: auto;
        position: relative;
    }

    &__left-container {
        align-items: center;
        display: flex;
        padding: 25px;
    }

    &__toggler {
        background-color: transparent;
        border: none;
        display: none;
        font-size: 1.3rem;

        @media (max-width: 768px) {
            display: block;
        }
    }

    &__title {
        color: $ecart-primary;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
        text-transform: capitalize;
    }

    &__navbar {
        padding-left: 45px;
        padding-right: 45px;
    }

    &__brand {
        width: 59px;
        height: 59px;
        display: none;
        @media(max-width: 992px) {
            width: auto;
            display: block;
        }
    }

    &__brand-logo {
        align-items: center;
        justify-content: center;
        display: block;
        padding: 0px 25px;
        width: calc(250px - 6rem);

        @media(max-width: 992px) {
            width: auto;
            display: none;
        }
    }

    &__notification-button {
        align-items: center;
        border: none;
        display: flex;
        width: 32px;
        height: 59px;
        font-size: 18px;
        justify-content: center;
        position: relative;
        background: $general-white;

        &:hover {
            background: $ecart-secondary-100;
        }
    }

    &__sidebar-button {
        align-items: center;
        border: none;
        width: 40px;
        height: 59px;
        font-size: 22px;
        justify-content: center;
        position: relative;
        background: $general-white;
        display: none;
        &:hover {
            background: $ecart-secondary-100;
        }
        @media(max-width: 992px) {
            width: auto;
            display: block;
        }
    }

    &__country-selector {
        margin-left: 5px;
        margin-right: 5px;
        width: 150px;
        display: block;

        @media(max-width: 992px) {
            width: auto;
            display: none;
        }
    }

    &__account-selector {
        width: 250px;
        display: block;

        @media(max-width: 1025px) {
            width: auto;
            display: none;
        }
    }

    &__profile-dropdown {
        display: flex;
        height: 59px;
        gap: 4px;
        border: none;
        padding: 0 16px 0px 16px;
        background: $general-white;
        border-left: 1px solid $ecart-secondary-200;
        border-right: 1px solid $ecart-secondary-200;
        align-items: center;
        cursor: pointer;
        &::after {
            display: none;
        }
        &:hover {
            background: $ecart-secondary-100;
        }
    }

    &__avatar {
        margin-right: 4px;
    }

    &__options {
        color: #495057;
    }

    &__profile-name {
        color: $general-black;
        size: 14px;
        line-height: 21px;
        font-weight: 600;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: normal;
    }

    &__profile-email {
        color: rgba($general-black, 0.7);
        size: 14px;
        line-height: 21px;
        font-weight: 500;
        text-align: left;
    }

    &__profile-name,
    &__profile-email {
        @media (max-width: 576px) {
            width: 80px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .profile-menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .profile-menu {
        background: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        z-index: 1040;
        width: 250px;
        margin-top: 60px;
        margin-left: auto;
        position: relative;

        @media (max-width: 992px) {
            margin-left: calc(100% - 310px);
        }
    }

    .profile-menu ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .profile-menu li {
        padding: 10px 20px;
    }

    .profile-menu li a {
        text-decoration: none;
        color: #333;
        display: block;
    }

    .profile-menu li a:hover {
        background: #f5f5f5;
    }
}

</style>
