'use strict';

import { view } from '@/functions';
const prefix = '/rules';

export default [
    {
        path: prefix,
        name: 'rules',
        meta: {
            scopes: ['read_rules']
        },
        component: () => view('rules/index'),
    },
]
