
const brands = [
    { label: 'Visa', value: 'visa' },
    { label: 'Mastercard', value: 'mastercard' },
    { label: 'American Express', value: 'amex' },
    { label: 'Discover', value: 'discover' },
    { label: 'Diners Club', value: 'diners' },
    { label: 'JCB', value: 'jcb' }
]; 
const filters = {
    brands,
    activation: {
        type: [
            {
                label: 'Individual',
                value: 'individual',
            },
            {
                label: 'Business',
                value: 'business',
            },
        ],
        status: [
            {
                label: 'Pending',
                value: 'pending',
            },
            {
                label: 'In review',
                value: 'in_review',
            },
            {
                label: 'Incomplete',
                value: 'incomplete',
            },
            {
                label: 'Accepted',
                value: 'accepted',
            },
            {
                label: 'Rejected',
                value: 'rejected',
            }
        ]
    },
    activity: {
        status: [
            {
                label: 'Created',
                value: 'created',
            },
            {
                label: 'Pending',
                value: 'pending',
            },
            {
                label: 'Failed',
                value: 'failed',
            },
            {
                label: 'Blocked',
                value: 'blocked',
            },
            {
                label: 'Paid',
                value: 'paid',
            }
        ],
        risk_level: [
            {
                label: 'Low',
                value: 'low',
            },
            {
                label: 'Medium',
                value: 'medium',
            },
            {
                label: 'High',
                value: 'high',
            },
            {
                label: 'Highest',
                value: 'highest',
            }
        ]
    },
    withdrawals: {
        status: [
            {
                label: 'Dispute Won',
                value: 'dispute_won',
            },
            {
                label: 'Dispute Lost',
                value: 'dispute_lost',
            },
            {
                label: 'Partially Refunded',
                value: 'partially_refunded',
            },
            {
                label: 'Block',
                value: 'block',
            },
            {
                label: 'Paid',
                value: 'paid',
            },
            {
                label: 'Refunded',
                value: 'refunded',
            },
            {
                label: 'Processing',
                value: 'processing',
            }
        ],
        gateway: [
            {
                label: 'Stripe',
                value: 'stripe',
            },
            {
                label: 'Conekta',
                value: 'conekta',
            },
            {
                label: 'Bbva',
                value: 'bbva',
            },
            {
                label: 'Razorpay',
                value: 'razorpay',
            },
            {
                label: 'Santander',
                value: 'santander',
            },
            {
                label: 'Transbank',
                value: 'transbank',
            },
            {
                label: 'Wompi',
                value: 'wompi',
            },
            {
                label: 'Pay',
                value: 'pay',
            },
            {
                label: 'Pagalo',
                value: 'pagalo',
            },
            {
                label: 'Stp',
                value: 'stp',
            },
            {
                label: 'Mercado Pago',
                value: 'mercadopago',
            },
            {
                label: 'Ecart Pay',
                value: 'ecartpay',
            },
            {
                label: 'PayU',
                value: 'payu',
            },
            {
                label: 'Manual',
                value: 'manual',
            }
        ],
        currency: [
            {
                label: 'MXN',
                value: 'MXN',
            },
            {
                label: 'USD',
                value: 'USD',
            },
            {
                label: 'BRL',
                value: 'BRL',
            },
            {
                label: 'CAD',
                value: 'CAD',
            },
            {
                label: 'ARS',
                value: 'ARS',
            },
            {
                label: 'COP',
                value: 'COP',
            },
            {
                label: 'EUR',
                value: 'EUR',
            },
            {
                label: 'PEN',
                value: 'PEN',
            },
            {
                label: 'JPY',
                value: 'JPY',
            },
            {
                label: 'RUB',
                value: 'RUB',
            },
            {
                label: 'GBP',
                value: 'GBP',
            },
            {
                label: 'CLP',
                value: 'CLP',
            },
            {
                label: 'UYU',
                value: 'UYU',
            },
            {
                label: 'GTQ',
                value: 'GTQ',
            },
            {
                label: 'INR',
                value: 'INR',
            },
            {
                label: 'CNY',
                value: 'CNY',
            },
            {
                label: 'AUD',
                value: 'AUD',
            },
            {
                label: 'SGD',
                value: 'SGD',
            },
        ],
    },
    transactions: {
        type: [
            {
                label: 'Charge',
                value: 'charge',
            },
            {
                label: 'Payment',
                value: 'payment',
            },
        ],
        status: [
            {
                label: 'Dispute Won',
                value: 'dispute_won',
            },
            {
                label: 'Dispute Lost',
                value: 'dispute_lost',
            },
            {
                label: 'Partially Refunded',
                value: 'partially_refunded',
            },
            {
                label: 'Block',
                value: 'block',
            },
            {
                label: 'Paid',
                value: 'paid',
            },
            {
                label: 'Refunded',
                value: 'refunded',
            },
            {
                label: 'Processing',
                value: 'processing',
            }
        ],
        gateway: [
            {
                label: 'Stripe',
                value: 'stripe',
            },
            {
                label: 'Conekta',
                value: 'conekta',
            },
            {
                label: 'Bbva',
                value: 'bbva',
            },
            {
                label: 'Razorpay',
                value: 'razorpay',
            },
            {
                label: 'Santander',
                value: 'santander',
            },
            {
                label: 'Transbank',
                value: 'transbank',
            },
            {
                label: 'Wompi',
                value: 'wompi',
            },
            {
                label: 'Pay',
                value: 'pay',
            },
            {
                label: 'Pagalo',
                value: 'pagalo',
            },
            {
                label: 'Stp',
                value: 'stp',
            },
            {
                label: 'Mercado Pago',
                value: 'mercadopago',
            },
            {
                label: 'Ecart Pay',
                value: 'ecartpay',
            },
            {
                label: 'PayU',
                value: 'payu',
            },
            {
                label: 'Manual',
                value: 'manual',
            }
        ],
        currency: [
            {
                label: 'MXN',
                value: 'MXN',
            },
            {
                label: 'USD',
                value: 'USD',
            },
            {
                label: 'BRL',
                value: 'BRL',
            },
            {
                label: 'CAD',
                value: 'CAD',
            },
            {
                label: 'ARS',
                value: 'ARS',
            },
            {
                label: 'COP',
                value: 'COP',
            },
            {
                label: 'EUR',
                value: 'EUR',
            },
            {
                label: 'PEN',
                value: 'PEN',
            },
            {
                label: 'JPY',
                value: 'JPY',
            },
            {
                label: 'RUB',
                value: 'RUB',
            },
            {
                label: 'GBP',
                value: 'GBP',
            },
            {
                label: 'CLP',
                value: 'CLP',
            },
            {
                label: 'UYU',
                value: 'UYU',
            },
            {
                label: 'GTQ',
                value: 'GTQ',
            },
            {
                label: 'INR',
                value: 'INR',
            },
            {
                label: 'CNY',
                value: 'CNY',
            },
            {
                label: 'AUD',
                value: 'AUD',
            },
            {
                label: 'SGD',
                value: 'SGD',
            },
        ],
    },
    risks: {
        level: [
            {
                label: 'Danger',
                value: 'danger',
            },
            {
                label: 'Warning',
                value: 'warning',
            }
        ]
    },
    refunds: {
        status: [
            {
                label: 'Complete',
                value: 'complete',
            },
            {
                label: 'Processing',
                value: 'Processing',
            },
            {
                label: 'Paid',
                value: 'paid',
            },
        ],
        gateway: [
            {
                label: 'Stripe',
                value: 'stripe',
            },
            {
                label: 'Conekta',
                value: 'conekta',
            },
            {
                label: 'Bbva',
                value: 'bbva',
            },
            {
                label: 'Razorpay',
                value: 'razorpay',
            },
            {
                label: 'Santander',
                value: 'santander',
            },
            {
                label: 'Transbank',
                value: 'transbank',
            },
            {
                label: 'Wompi',
                value: 'wompi',
            },
            {
                label: 'Pay',
                value: 'pay',
            },
            {
                label: 'Pagalo',
                value: 'pagalo',
            },
            {
                label: 'Stp',
                value: 'stp',
            },
            {
                label: 'Mercado Pago',
                value: 'mercadopago',
            },
            {
                label: 'Ecart Pay',
                value: 'ecartpay',
            },
            {
                label: 'PayU',
                value: 'payu',
            },
            {
                label: 'Manual',
                value: 'manual',
            }
        ],
    },
    transfers: {
        status: [
            {
                label: 'Complete',
                value: 'complete',
            },
            {
                label: 'Processing',
                value: 'Processing',
            },
            {
                label: 'Paid',
                value: 'paid',
            },
        ],
        currency: [
            {
                label: 'MXN',
                value: 'MXN',
            },
            {
                label: 'USD',
                value: 'USD',
            },
            {
                label: 'BRL',
                value: 'BRL',
            },
            {
                label: 'CAD',
                value: 'CAD',
            },
            {
                label: 'ARS',
                value: 'ARS',
            },
            {
                label: 'COP',
                value: 'COP',
            },
            {
                label: 'EUR',
                value: 'EUR',
            },
            {
                label: 'PEN',
                value: 'PEN',
            },
            {
                label: 'JPY',
                value: 'JPY',
            },
            {
                label: 'RUB',
                value: 'RUB',
            },
            {
                label: 'GBP',
                value: 'GBP',
            },
            {
                label: 'CLP',
                value: 'CLP',
            },
            {
                label: 'UYU',
                value: 'UYU',
            },
            {
                label: 'GTQ',
                value: 'GTQ',
            },
            {
                label: 'INR',
                value: 'INR',
            },
            {
                label: 'CNY',
                value: 'CNY',
            },
            {
                label: 'AUD',
                value: 'AUD',
            },
            {
                label: 'SGD',
                value: 'SGD',
            },
        ],
    },
}

export default filters;
