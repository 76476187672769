'use strict';

import { view } from '@/functions';

const prefix = '/utils';
export default [
    {
        path: prefix,
        name: 'utils',
        meta: {
            scopes: ['read_utils']
        },
        component: () => view('utils/index'),
    },
];
