export default {
    links: [
        {
            path: '/accounts',
            name: 'Accounts',
            icon: 'fas fa-user-friends',
            children: [
                {
                    path: '/accounts',
                    name: 'Accounts',
                    scope: ['read_accounts', 'write_accounts'],
            filtered: true,
                },
                {
                    path: '/leads',
                    name: 'Leads',
                    filtered: false,
                    scope: ['read_leads', 'write_leads'],
                },
                {
                    path: '/activations',
                    name: 'Activations',
                    filtered: false,
                    scope: ['read_activations', 'write_activations'],
                },
            ],
        },
        {
            name: 'Process',
            icon: 'fas fa-money-bill',
            children: [
                {
                    path: '/transactions',
                    name: 'Transactions',
                    filtered: true,
                    scope: ['read_transactions', 'write_transactions'],
                },
                {
                    path: '/withdrawals',
                    name: 'Withdrawals',
                    filtered: true,
                    scope: ['read_withdrawals', 'write_withdrawals'],
                },
                {
                    path: '/payouts',
                    name: 'Payouts',
                    filtered: true,
                    scope: ['read_payouts'],
                },
                {
                    path: '/chargebacks',
                    name: 'Chargebacks',
                    filtered: true,
                    scope: ['read_chargebacks', 'write_chargebacks'],
                },
                {
                    path: '/refunds',
                    name: 'Refunds',
                    filtered: true,
                    scope: ['read_refunds'],
                },
                {
                    path: '/activitys',
                    name: 'Activity',
                    filtered: true,
                    scope: ['read_activity', 'write_activity'],
                },
                {
                    path: '/invoices',
                    name: 'Invoices',
                    filtered: true,
                    scope: ['read_invoices', 'write_invoices'],
                },
                {
                    path: '/transfers',
                    name: 'Transfers',
                    filtered: true,
                    scope: ['read_transfers', 'write_transfers'],
                },
            ],
        },
        {
            name: 'Administration',
            icon: 'fas fa-clipboard-list',
            children: [
                {
                    path: '/reports',
                    name: 'Reports',
                    filtered: false,
                    scope: ['read_reports'],
                },
                {
                    path: '/admins',
                    name: 'Admins',
                    filtered: false,
                    scope: ['read_admins', 'write_admins'],
                },
                
                {
                    path: '/risks',
                    name: 'Risks',
                    filtered: false,
                    scope: ['read_risks'],
                },
                {
                    path: '/rules',
                    name: 'Rules',
                    filtered: false,
                    scope: ['read_rules'],
                },
                {
                    path: '/webhook-logs',
                    name: 'Webhook Logs',
                    filtered: false,
                    scope: ['read_webhooklogs'],
                },
                {
                    path: '/utils',
                    name: 'Utils',
                    filtered: false,
                    scope: ['read_utils'],
                },
                {
                    path: '/profiles',
                    name: 'Profiles',
                    filtered: false,
                    scope: ['read_profiles', 'write_profiles']
                },
                {
                    path: '/whitelist',
                    name: 'Whitelists',
                    filtered: false,
                    scope: ['read_whitelist', 'write_whitelist']
                }
            ],
        }
    ],
    otherLinks: [
        {
            path: '/dashboard/profile',
            name: 'Your Profile',
            icon: 'fas fa-user',
        },
    ],
};
